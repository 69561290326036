

const Mutations = {
	_muts: {},

	add: (sectionKey, fieldName, fieldValue, fieldType) => {
		if (typeof Mutations._muts[sectionKey] == 'undefined') {
			Mutations._muts[sectionKey] = {}
		}

		// Only save the image ID for images
		if (fieldType === 'image') {
			fieldValue = fieldValue.split(';')[0]
		}

		Mutations._muts[sectionKey][fieldName] = fieldValue
	},

	all: () => {
		return Mutations._muts
	},

	reset: () => {
		Mutations._muts = {}
	},


	// Will contain iumage upload states
	// This is nessesary because the input might not be rendered during the upload, for instance
	// if a different section is selected and the input gets discarded
	// An input can look up if it is represented in this state object and subscribe to progress
	// updates by adding its setter to this object, which uploadImage() will call
	uploadStates: {},

	// Start upload of image while keeping the input up to date an its progress
	uploadImage: (sectionKey, fieldName, file, setter, callback, domain, user) => {
		// Create state for section if it does not already exist
		if (!Mutations.uploadStates[sectionKey]) Mutations.uploadStates[sectionKey] = {}
		// Init state
		const state = Mutations.uploadStates[sectionKey][fieldName] = {
			progress: 0,
			setter: setter,
			preview: false
		}

		// Read the image into a dataurl to show a preview in the input
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => {
			state.preview = reader.result
		}

		// Get image encoded for transmission
		let formdata = new FormData()
		formdata.append('image', file)

		// You'd think we'd use fetch, but even in 2024 not all browsers support getting the upload progress
		let request = new XMLHttpRequest()
		request.responseType = 'json';

		// Inform the input about status changes to the
		request.upload.addEventListener('progress', event => {
			state.setter(event.loaded / event.total * 100)
		})

		// When the upload is done
		request.onreadystatechange = function() {
			if (this.readyState === this.DONE) {
				// Inform the input and thus the section array of the change
				callback(request.response)
				// Reset the input progress
				state.setter(null)
				// Add mutation for new image
				Mutations.add(sectionKey, fieldName, request.response.id.toString(), 'image')
				// Reset state
				delete Mutations.uploadStates[sectionKey][fieldName]
			}
		}

		// Upload it with the correct JWT
		request.open('post', domain + '/ww-pe-api/uploadimage/')
		request.setRequestHeader('X-token', user.sites[user.activeSite].token)
		request.send(formdata)
	}
}

export default Mutations;
