import './TabStyle.scss'

import { useState } from 'react';
import { useContext } from 'react'
import { useQuery } from '@tanstack/react-query'

import { UserContext } from '../../App.js'

const TabStyle = () => {
	const { user } = useContext(UserContext)

	const [accentColor, setAccentColor] = useState('#000')

	const defaultColors = [
		['#9B1D20', 'Carmine'],
		['#3D2B3D', 'Dark purple'],
		['#21D19F', 'Mint'],
		['#416788', 'Lapis Lazuli'],
		['#D282A6', 'Thulian'],
		['#303A2B', 'Dark olive'],
		['#CFCFEA', 'Lavender'],
		['#FCB5B5', 'Melon'],
		['#DA3E52', 'Amaranth'],
		['#F2E94E', 'Maize'],
		['#7E6B8F', 'Violet'],
		['#2A2B2A', 'Jet'],
		['#4C9F70', 'Shamrock'],
		['#011638', 'Oxford'],
		['#70E4EF', 'Electric blue']
	]


	function changeAccentColor(newColor) {
		setAccentColor(newColor)

		var r = document.querySelector(':root');

		r.style.setProperty('--color-accent', newColor);

	}



	const { data } = useQuery({
		queryKey: ['styling'],
		queryFn: () => {
			return fetch(process.env.REACT_APP_RENDER_DOMAIN.replace('SITEID', user.activeSite) + '/ww-pe-api/getstyling/', {
				headers: {
					'X-token': user.sites[user.activeSite].token
				}
			}).then(res => {
				return res.json()
			}).then(res => {
				if (accentColor !== res.color) setAccentColor(res.color)
				return res
			})
		}
	})


	let fonts = []
	let colors = []

	if (data && data.fonts) {
		for (let font of data.fonts) {
			fonts.push(<div key={font.name} style={{fontFamily: `preview-${font.family}-${font.weight}`}}>
				<style>@font-face {'{'}
					font-family: "preview-{font.family}-{font.weight}";
					font-style: {font.style};
					font-weight: {font.weight};
					src: url("data:font/woff2;base64,{font.base64}") format("woff2");
				{'}'}</style>

				<div>
					<span>{font.family}</span>
				</div>
				<div>
					<button>Activate</button>
				</div>
			</div>)
		}

		let isCustomColor = true

		for (let color of defaultColors) {
			if (accentColor === color[0]) isCustomColor = false
			console.log(accentColor === color[0],accentColor , color[0]);

			colors.push(<div key={color[0]} className={accentColor === color[0] ? 'active' : ''} onClick={() => {changeAccentColor(color[0])}}>
				<div style={{background: color[0]}}></div>
				{color[1]}
			</div>)
		}

		colors.push(<div key="custom" className={isCustomColor ? 'active' : ''}>
			<div style={{background: data.color}}></div>
			Custom color
		</div>)
	}


	return <div className="TabStyle">
		<h2>Colors</h2>

		<div className="TabStyleColors">
			{colors}
		</div>

		<h2>Font</h2>

		<div className="TabStyleFonts">
			{fonts}
		</div>
	</div>
}

export default TabStyle
