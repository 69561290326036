import './BaseInput.scss';
import './BooleanInput.scss';

import React from 'react'

const TextInput = (props) => {
	// Shorthand
	let field = props.field

	return <div className="BaseInput BooleanInput">
		<div>
		 <h6>{field.name}</h6>

			{field.instructions && field.instructions.length > 0 &&
				<p>{field.instructions}</p>
			}
		</div>

		<div>
			<input
				type="checkbox"
				defaultChecked={field.value === '1'}
				onChange={props.onChange} />
		</div>
	</div>
}

export default TextInput
