import React from 'react';

const SliderInput = (props) => (
	<div>
		<input
			type="range"
			value={props.value}
			onChange={props.onChange} />
	</div>
);

export default SliderInput;
