import {useState, forwardRef} from 'react'
import DOMPurify from 'dompurify'

const SCALE_SIZE = 5

const SectionHandle = forwardRef((props, ref) => {
	// Keeps track of the start postion of a possible drag, to decide if it is a click or not
	const [startY, setStartY] = useState(-1)
	// Wether or not to show the "don't drag banners" tooltip
	const [showTooltip, setShowTooltip] = useState(false)

	// Move element to offset when dragging, position is calculated by parent
	let topOffset = '0px'
	if (props.dragOffset > -1) {
		// Compensate for top section header, 20% scale
		topOffset = Math.max(props.dragOffset - 65, 0) * SCALE_SIZE + 'px'
	}

	const isBanner = props.section.module.substring(1, 0) === '2'

	// Called when a mouse button is pressed on the section
	function mouseDown(event) {
		// Only allow left clicks
		if (event.button !== 0) return
		// Start tracking the Y change of the mouse, to see if it is a click or drag
		setStartY(event.pageY);
	}

	// Called when the mouse is moved within the section
	function mouseMove(event) {
		// If the mouse is down (startY) and has moved enough, it's a drag
		if (startY !== -1 && Math.abs(event.pageY - startY) > 2 * SCALE_SIZE) {
			setStartY(-1)
			startDrag(event.pageY)
		}
	}

	// Called on mouse release
	function mouseUp(event) {
		// If the mouse was down on this specific section before
		if (startY === -1) return
		setStartY(-1)

		// If not dragging
		if (props.dragOffset === -1) {
			// It's a click, change the active section to this one
			props.changeActiveSection(props.section.key)
		}
	}

	// If it defenitely is a drag and not a click
	function startDrag(mouseY) {
		// Show warning if we're a banner
		if (isBanner) {
			setShowTooltip(true)
			setTimeout(() => setShowTooltip(false), 2500)
		}
		// Init the drag if we're a section
		else {
			props.startDrag(props.section.key, mouseY)
		}
	}


	return <div
		id={props.section.key}
		key={props.section.key}
		className={'SectionHandle ' + props.section.className + (showTooltip ? ' tooltip' : '')}
		onMouseDown={mouseDown}
		onMouseMove={mouseMove}
		onMouseUp={mouseUp}
		style={{top: topOffset}}>
		<div>
			<span onClick={() => {props.reorderSection(props.activeSection - 2, props.activeSection - 1)}}>^</span>

			<span onMouseDown={event => {startDrag(event.pageY)}}>
				::
			</span>

			<span onClick={() => {props.reorderSection(props.activeSection + 1)}}>⌵</span>
		</div>
		<div>
			<div>
				<span>{props.section.key}{props.section.name}</span>
				<div>
					<button onClick={() => {props.deleteSection(props.section.key)}}>D</button>
				</div>
			</div>
			<main dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.section.html)}} key={props.section.key}></main>
		</div>

		{isBanner && <div></div>}
		{isBanner && <div>Banners can only be at the top of a page</div>}
	</div>
})

export default SectionHandle
