import './TopBar.scss'

import { useContext } from 'react';

import { UserContext } from './App.js'

const TopBar = (props) => {
	const { user } = useContext(UserContext)

	const domain = process.env.REACT_APP_RENDER_DOMAIN.replace('SITEID', user.activeSite)

	return <div className="TopBar">
		<img src={domain + '/wp-content/themes/wasabiweb/custom/assets/dist/img/site-logo-dark.svg'} />
	</div>
};

export default TopBar;
