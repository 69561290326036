import './ImageInput.scss';

import {useState, useEffect, useContext} from 'react'
import Mutations from '../Mutations'

import {UserContext} from '../../App.js'

const ImageInput = (props) => {
	const {user} = useContext(UserContext)

	// Shorthand
	let field = props.field
	if (!field.value) field.value = ''
	let parts = field.value.split(';')

	// Show saved image by default
	let imgURL = parts[1]
	// See if there is an ongoing upload state, default to false
	let state = Mutations.uploadStates[props.sectionKey]
	state = state ? state[field.key] : false

	const [className, setClassName] = useState('ImageInput')
	const [uploadProgress, setUploadProgress] = useState(state ? Mutations.uploadStates[props.sectionKey][field.key].progress : null)

	// If there is an upload, set our setter so that we get updates about it
	useEffect(() => {
		if (state) {
			state.setter = setUploadProgress
			if (state.preview) imgURL = state.preview
		}
	})

	function openModal() {
		props.modal.current.select({
			sectionKey: props.sectionKey,
			fieldName: field.key,
			setter: setUploadProgress,
			callback: onNewImage
		})
	}

	// Triggered by modal when a new image is selected
	function onNewImage(image) {
		props.onChange(image.id + ';' + image.small + ';' + image.title)
	}

	// Triggered when file is dragged into element
	function dragIn(event) {
		if (!event.target.classList.contains('ImageInput')) return
		if (!event.dataTransfer.types.includes('Files')) return

		event.preventDefault()
		setClassName('ImageInput filedrag')
	}

	// Triggered when file is no longer over element, reset
	function dragOut(event) {
		if (!event.target.classList.contains('ImageInput')) return
		setClassName('ImageInput')
	}

	// Triggered when a file is dropped on element
	function fileDrop(event) {
		event.preventDefault()
		setClassName('ImageInput')

		if (event.dataTransfer.files) {
			setUploadProgress(0)
			Mutations.uploadImage(props.sectionKey, field.key, event.dataTransfer.files[0], setUploadProgress, onNewImage, props.domain, user)
		}
	}


	return <div className="BaseInput">
		<h6>{field.name}</h6>

		<div className={className}
			onDragOver={dragIn}
			onDragLeave={dragOut}
			onDrop={fileDrop}>
			<img src={imgURL} alt={parts[2]} />

			<div>
				{
					!state && <div>
						<small>{parts[2]}</small><br/>
						<button onClick={openModal}>Change</button>
					</div>
				}

				{
					state && <div>
						<small>
							{(uploadProgress !== null && uploadProgress < 100) && `Uploading... ${Math.floor(uploadProgress)}%`}
							{(uploadProgress === null || uploadProgress >= 100) && 'Optimizing...'}
						</small><br/>
						<progress max={uploadProgress > 0 ? 100 : null} value={uploadProgress}></progress>
					</div>
				}
			</div>
		</div>
	</div>
}

export default ImageInput
