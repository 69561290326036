import './SiteSettings.scss'

import { useContext } from 'react'
import { Link } from 'wouter'

import { UserContext } from '../App.js'
import Request from '../Request.js'

import TopBar from '../TopBar'
import TabBasic from './tabs/TabBasic'
import TabMenu from './tabs/TabMenu'
import TabStyle from './tabs/TabStyle'



const SiteSettings = props => {
	const {user} = useContext(UserContext)
	const localURL = '/site/' + user.activeSite + '/settings/'

	return <div className="SiteSettings">
		<TopBar />

		<div>
			<div>
				<Link href={localURL + 'basic'} className={props.params.tab === 'basic' ? 'active' : ''}>
					Basics
				</Link>
				<Link href={localURL + 'menu'} className={props.params.tab === 'menu' ? 'active' : ''}>
					Main menu
				</Link>
				<Link href={localURL + 'style'} className={props.params.tab === 'style' ? 'active' : ''}>
					Fonts & colors
				</Link>
			</div>
			<div>
				{props.params.tab === 'basic' && <TabBasic />}
				{props.params.tab === 'menu' && <TabMenu />}
				{props.params.tab === 'style' && <TabStyle />}
			</div>
		</div>
	</div>
}

export default SiteSettings
