import './Editor.scss';

import React, { useState, useRef, useContext } from 'react';
import Frame from 'react-frame-component';
import { Link } from 'wouter';

import Mutations from './Mutations'
import { UserContext } from '../App.js'

import PlatformManager from './PlatformManager';
import SaveButton from './SaveButton';
import SectionRoot from './sections/SectionRoot';

import ImageModal from './imagemodal/ImageModal';
import SectionModal from './SectionModal';

import TextInput from './inputs/TextInput';
import BooleanInput from './inputs/BooleanInput';
import SliderInput from './inputs/SliderInput';
import ImageInput from './inputs/ImageInput';

function Editor(props) {
	const { user } = useContext(UserContext)

	const domain = process.env.REACT_APP_RENDER_DOMAIN.replace('SITEID', user.activeSite)
	const postID = props.params.pageid

	// Ref to in-frame sectionroot to help with mouse events during section dragging
	const sectionRootRef = useRef(null)
	// Ref used to control the site preview from anywhere in the editor
	const platformManagerRef = useRef(null)
	// FIXME: The save button is in control of saving the page, should be moved to Mutations maybe?
	const saveButtonRef = useRef(null)

	// Refs to modals so they can be opened elsewhere in the app
	const sectionModalRef = useRef(null)
	const imageModalRef = useRef(null)


	// Will be filled by the platform iframe with the default platform styling
	const [platformStyles, setPlatformStyles] = useState(<style></style>)
	// Keeps track of the currently activly edited section index
	const [activeSection, setActiveSection] = useState(0)
	// Will contain all the sections and their fields, updated by page editor script in iframe
	const [sections, setSections] = useState([])
	// Set to true to disable all pointer events except ending the drag
	const [isDragging, setIsDragging] = useState(false)

	// Called when an input updates
	const valueUpdate = (key, value) => {
		// Make a modifiable version of the section array
		const updatedSections = [...sections]

		// Update the value
		const field = updatedSections[activeSection].fields.find(item => {
			return item.key === key
		})

		if (field.type === 'true_false') {
			field.value = value.target.checked ? 1 : 0
		}
		else if (field.type === 'image') {
			field.value = value
		}
		else {
			field.value = value.target.value
		}

		// Update the sections array
		setSections(updatedSections);

		// Despatch an update to the platform iframe to update
		platformManagerRef.current.sendUpdateMessage(sections[activeSection].key, field.key, field.value);

		// Log mutation so it can be saved to db
		Mutations.add(sections[activeSection].key, field.key, field.value, field.type)

		// If field changes can only be done by reloading the page
		if (field.reload) {
			saveButtonRef.current.forceSave()
		}
	}

	let inputs = [];
	// If a section is selected
	if (sections[activeSection]) {
		// Render an input for each defined field
		inputs = sections[activeSection].fields.map((field, index) => {
			switch (field.type) {
				case 'text':
					return <TextInput key={field.key} field={field} onChange={e => valueUpdate(field.key, e)} />;
				case 'true_false':
					return <BooleanInput key={field.key} field={field} onChange={e => valueUpdate(field.key, e)} />;
				case 'slider':
					return <SliderInput key={field.key} field={field} onChange={e => valueUpdate(field.key, e)} />;
				case 'image':
					return <ImageInput
						key={field.key}
						field={field}
						onChange={v => valueUpdate(field.key, v)}
						sectionKey={sections[activeSection].key}
						domain={domain}
						modal={imageModalRef} />;
				default:
					// Fail on unknown type
					// console.warn("Unknown field type", field.type, field);
					return null;
			}
		});
	}


	return (
		<div
			className={'Editor'}
			onMouseUp={evt => {if (isDragging && sectionRootRef.current) sectionRootRef.current.mouseRelease(evt)}}>
			<nav>
				<h3>
					<Link href={'/site/' + user.activeSite}>Back</Link>
					Sections
				</h3>
				<div>
					<Frame
						initialContent="<!DOCTYPE html><html><head><link rel='stylesheet' href='/static/css/sections.css'></head><body><div></div></body></html>"
						head={platformStyles}>
						<SectionRoot
							ref={sectionRootRef}
							setIsDragging={setIsDragging}
							postID={postID}
							domain={domain}
							sections={sections}
							activeSection={activeSection}
							setActiveSection={setActiveSection}
							platformManagerRef={platformManagerRef}
							modal={sectionModalRef} />
					</Frame>
				</div>
			</nav>

			<main>
				<h3>{sections[activeSection] ? sections[activeSection].name : 'Fields'}</h3>
				<div>{inputs}</div>
			</main>

			<section>
				<h3>
					Live preview

					<a href={`http://${user.activeSite}.usersite.jiko.se`} target="_blank">
						See live page
					</a>
					<SaveButton
						ref={saveButtonRef}
						domain={domain}
						postID={postID}
						platformManagerRef={platformManagerRef} />
					<SaveButton
						publish={true}
						domain={domain}
						postID={postID}
						platformManagerRef={platformManagerRef} />
				</h3>
				<PlatformManager
					src={domain + '?page_id=' + postID + '&donotcachepage=1'}
					sections={sections}
					activeSection={activeSection}
					setPlatformStyles={setPlatformStyles}
					setSections={setSections}
					ref={platformManagerRef}
				/>
			</section>


			<SectionModal
				ref={sectionModalRef}
				domain={domain}
				postID={postID}
				activeSection={activeSection}
				setActiveSection={setActiveSection}
				platformManagerRef={platformManagerRef} />

			<ImageModal
				ref={imageModalRef}
				domain={domain} />
		</div>
	);
}

export default Editor;
