import React, {useContext} from 'react'

import {UserContext} from '../../App.js'

const AddBannerButton = props => {
	const {user} = useContext(UserContext)

	function addBanner() {
		props.setIsBusy(true)

		fetch(props.domain + '/ww-pe-api/addsection/' + props.postID + '/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'X-token': user.sites[user.activeSite].token
			},
			body: JSON.stringify({
				key: 2001,
				is_banner: true
			})
		}).then(res => {
			return res.json()
		}).then(json => {
			props.platformManagerRef.current.refresh()
		})
	}

	return <button className="AddBannerButton" onClick={addBanner}>
		+ Add Banner
	</button>
}

export default AddBannerButton;
