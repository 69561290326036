import './Page.scss'

import { useContext } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Link } from 'wouter';

import { UserContext } from '../App.js'

const Page = (props) => {
	const {user} = useContext(UserContext)

	let {isLoading, error, data: html} = useQuery({
		queryKey: ['thumb' + user.activeSite + props.id],
		queryFn: () => {
			return fetch(`${process.env.REACT_APP_API_DOMAIN}/v1/site/${user.activeSite}/page/${props.id}/thumbnail`, {
				headers: {
					'X-token': user.token
				}
			}).then(res => {
				return res.text()
			})
		}
	})

	if (!html) {
		html = '<style>body{background:#eee}</style>'
	}

	return <Link href={'/site/' + user.activeSite + '/page/' + props.id} className="Page">
		<div>
			<iframe srcDoc={html} />
		</div>

		<strong>{props.title}</strong>
		<small>Last saved {props.modified}</small>
	</Link>
};

export default Page;
