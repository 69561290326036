import './TabImageList.scss';

import {useImperativeHandle, forwardRef, useState, useContext} from 'react'
import Modal from 'react-modal'
import {useQuery} from '@tanstack/react-query'

import {UserContext} from '../../App.js'
import Mutations from '../Mutations'

const TabImageList = (props) => {
	const {user, setUser} = useContext(UserContext)

	function checkUpload(event) {
		if (!event.target.files) return false
		props.uploadImage(event.target.files[0])
	}


	const {isLoading, error, data} = useQuery({
		queryKey: ['images'],
		queryFn: () => {
			return fetch(props.domain + '/ww-pe-api/images/', {
				headers: {
					'X-token': user.sites[user.activeSite].token
				}
			}).then(res => {
				return res.json()
			})
		}
	})


	let images = <strong>Still loading!</strong>

	if (data && data.images) {
		images = data.images.map((image, index) => {
			return <div
				key={image.id}
				id={image.id}
				onClick={() => props.selectNewImage(image)}>
					<div style={{backgroundImage: `url('${image.preload}')`}} />
					<img src={image.small} loading="lazy"/>
			</div>
		})
	}


	return <div className="TabImageList">
		<div>
			<input
			autoFocus
			type="text"
			placeholder="Search for an image name..." />
			<button onClick={() => {props.setIsOpen(false)}}>X</button>
		</div>

		<div>
			<label>
				<input onChange={checkUpload} type="file" />
				<span>📷</span>
				Upload image from your device
			</label>
			<div>
				<span>🔗</span>
				Import image from URL
			</div>
			<div onClick={() => props.setActiveTab('generate')}>
				<span>🖌️</span>
				Generate new AI image
			</div>
		</div>

		<div>
			{images}
		</div>
	</div>
}

export default TabImageList
