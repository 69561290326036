import './TabGenerate.scss';

import {useRef, useState, useContext} from 'react'
import Modal from 'react-modal'
import {useQuery} from '@tanstack/react-query'

import {UserContext} from '../../App.js'
import Mutations from '../Mutations'

const TabGenerate = (props) => {
	const {user, setUser} = useContext(UserContext)

	const [isBusy, setIsBusy] = useState(false)
	const [imgData, setImgData] = useState('')
	const inputRef = useRef()

	// Get a new generation from OpenAI
	function fetchGeneration() {
		if (isBusy) return
		setIsBusy(true)

		fetch(process.env.REACT_APP_API_DOMAIN + `/v1/site/${user.activeSite}/media/generate_image?` + new URLSearchParams({
			prompt: inputRef.current.value
		}), {
			headers: {
				'X-token': user.token
			}
		}).then(res => {
			return res.json()
		}).then(json => {
			setImgData(json.image)
			setIsBusy(false)
		})
	}

	// Set the generated image as the selected in the image input
	function selectGeneration() {
		if (imgData === '') return

		// Convert the base64 string into bytes
		let byteString = atob(imgData)
		// Create buffer and view with the correct number of bytes
		let buffer = new ArrayBuffer(byteString.length)
		let view = new Uint8Array(buffer)

		// Set the bytes of the buffer to the correct values
		for (let i = 0; i < byteString.length; i++) {
			view[i] = byteString.charCodeAt(i)
		}

		// Stuff it into a blob
		let blob = new Blob([buffer], {type: 'image/jpeg'})
		// Create filename from prompt, with a lil bug that the prompt might have changed
		let name = inputRef.current.value.split(' ').slice(0,5).join('_') + '.jpg'

		// Start the upload of the brand new file
		props.uploadImage(new File([blob], name, {type: 'image/jpeg'}))
	}



	return <div className="TabGenerate">
		<div>
			<div className={isBusy ? 'busy' : null} style={{backgroundImage: imgData !== '' ? `url('data:image/jpeg;base64,${imgData}')` : null}}>
				{(imgData === '' && !isBusy) && <span>Type a description of what you want on the right</span>}
				{isBusy && <span className='loader'></span>}
			</div>
		</div>

		<div>
			<input type="text" placeholder="Someone mowing a fancy lawn" disabled={isBusy} ref={inputRef} />
			<div>
				<button onClick={fetchGeneration} disabled={isBusy}>
					{imgData === '' ? 'Generate image' : 'Try another regenration'}
				</button>
				<button onClick={selectGeneration} disabled={imgData === ''}>Use image</button>
			</div>
		</div>
	</div>
}

export default TabGenerate
