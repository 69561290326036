import './TabMenu.scss'

import { useState, useContext } from 'react'
import { useQuery } from '@tanstack/react-query'

import Icon from '@mdi/react';
import { mdiDrag } from '@mdi/js';
import { mdiDelete } from '@mdi/js';

import { UserContext } from '../../App.js'

const TabMenu = () => {
	const { user } = useContext(UserContext)

	const [items, setItems] = useState([])
	const [unsavedChanges, setUnsavedChanges] = useState(false)

	const [inputCustomTitle, setInputCustomTitle] = useState('')
	const [inputCustomUrl, setInputCustomUrl] = useState('')

	function saveMenu() {
		fetch(process.env.REACT_APP_RENDER_DOMAIN.replace('SITEID', user.activeSite) + '/ww-pe-api/setmenu/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'X-token': user.sites[user.activeSite].token
			},
			body: JSON.stringify({
				items: items
			})
		}).then(res => {
			return res.json()
		}).then(json => {
			setItems(json.items)
			setUnsavedChanges(false)
		})
	}

	function addMenuItem(type, data) {
		setUnsavedChanges(true)
		setItems([
			...items,
			{
				children: [],
				id: 0,
				parent: 0,
				title: data.title,
				type: type,
				url: data.url
			}
		])
	}

	function renameMenuItem(changeId, newTitle) {
		setUnsavedChanges(true)
		for (let item of items) {
			if (item.id == changeId) item.title = newTitle
		}
	}

	function removeMenuItem(delId) {
		setUnsavedChanges(true)
		setItems(items.filter(item => {
			return item.id !== delId
		}))
	}


	const { data } = useQuery({
		queryKey: ['menuItems'],
		queryFn: () => {
			return fetch(process.env.REACT_APP_RENDER_DOMAIN.replace('SITEID', user.activeSite) + '/ww-pe-api/listmenu/', {
				headers: {
					'X-token': user.sites[user.activeSite].token
				}
			}).then(res => {
				return res.json()
			})
		}
	})

	let itemEls = []

	if (data && data.items) {
		let itemData = items

		if (!unsavedChanges && data.items != items) {
			setItems(data.items)
			itemData = data.items

			console.log(data.items);
		}

		function readItems(items) {
			let out = []

			for (let item of items) {
				out.push(<li key={item.id}>
					<div>
						<span><Icon path={mdiDrag} size={1} /></span>
						<input type="text" value={item.title} onChange={event => {renameMenuItem(item.id, event.target.value)}} />
						<Icon path={mdiDelete} size={1} onClick={() => {removeMenuItem(item.id)}} />


					</div>
					<ul>{readItems(item.children)}</ul>
				</li>)
			}

			return out
		}

		itemEls = readItems(itemData)
	}





	return <div className="TabMenu">
		<h2>
			Menu
			<button onClick={addMenuItem}>add new</button>
		</h2>


		{unsavedChanges && <div>You have unsaved changes, don't forget to save them <button onClick={saveMenu}>Save</button></div>}

		<ul>
		 {itemEls}
		</ul>

		<div>
			Add custom Link

			<input type="text" value={inputCustomTitle} onChange={(e) => setInputCustomTitle(e.target.value)}  />
			<input type="url" value={inputCustomUrl} onChange={(e) => setInputCustomUrl(e.target.value)}  />

			<button onClick={() => {addMenuItem('custom', {title: inputCustomTitle, url: inputCustomUrl})}}>Add link to bottom of menu</button>
		</div>
	</div>
}

export default TabMenu
