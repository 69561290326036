import './BaseInput.scss';

import React from 'react'

const TextInput = (props) => {
	// Shorthand
	let field = props.field

	return <div className="BaseInput">
	 <h6>{field.name}</h6>

		{field.instructions && field.instructions.length > 0 &&
			<p>{field.instructions}</p>
		}

		<input
			type="text"
			value={field.value}
			onChange={props.onChange} />
	</div>
}

export default TextInput
