import './Login.scss'

import { useState, useContext } from 'react'
import { Link, useLocation } from 'wouter'

import Icon from '@mdi/react'
import { mdiLogin, mdiArrowRightThin } from '@mdi/js'

import { UserContext } from '../App'
import Request from '../Request'

const Login = () => {
	const [errorMessage, setErrorMessage] = useState(false)

	const {user, setUser} = useContext(UserContext)
	const [location, setLocation] = useLocation()

	const handleSubmit = event => {
		// Prevent page reload
		event.preventDefault()
		fetch(process.env.REACT_APP_API_DOMAIN + '/v1/login', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				email: event.target.email.value,
				pass: event.target.pass.value
			})
		}).then(res => {
			return res.json()
		}).then(json => {
			if (!json.success) {
				return setErrorMessage('Incorrect username or password')
			}

			user.token = json.token
			user.email = json.email
			user.sites = json.sites
			user.activeSite = Object.keys(user.sites)[0]

			Request.updateTokens(user)
			setUser(user)

			localStorage['UserContext'] = JSON.stringify(user)

			setLocation('/site/' + user.sites[user.activeSite].pub_id)
		}).catch((e) => {
			console.error(e);
			setErrorMessage('Could not contact server, try again later')
		})
	}


	return <div className="Login">
		<div>

		</div>
		<div>
			<svg viewBox="0 0 1314 221">
				<path d="M153 218h-21l-30-107-4-18-3 17-30 108H43L0 68h26l26 108 3 18 4-18L89 68h21l29 108 4 18 4-18 26-108h23l-43 150zm116 3c-36 0-57-16-57-46 0-36 35-50 84-54v-9c0-21-12-30-32-30-13 0-28 4-38 9l-6-15c13-6 30-11 46-11 32 0 52 16 52 49v96c-12 7-29 11-49 11zm27-87c-38 4-61 15-61 41 0 20 13 31 36 31 9 0 19-1 25-5v-67zm94 87c-15 0-31-4-41-11l7-15c9 5 21 8 31 8 18 0 29-11 29-25 0-37-63-22-63-72 0-23 18-41 46-41 14 0 27 4 37 11l-6 14c-8-5-18-8-28-8-17 0-25 10-25 23 0 34 64 20 64 71 0 25-21 45-51 45zm135 0c-36 0-57-16-57-46 0-36 36-50 84-54v-9c0-21-11-30-32-30-13 0-27 4-37 9l-6-15c12-6 29-11 45-11 33 0 53 16 53 49v96c-12 7-30 11-50 11zm27-87c-38 4-61 15-61 41 0 20 13 31 36 31 10 0 19-1 25-5v-67zm106 87c-17 0-34-5-44-11V0h24v73c9-5 19-7 30-7 35 0 59 29 59 74 0 49-24 81-69 81zm4-140c-9 0-17 2-24 5v115c6 3 14 4 21 4 31 0 43-26 43-64s-14-60-40-60zm112-41c-8 0-15-6-15-14 0-7 7-13 15-13s15 6 15 13c0 8-7 14-15 14zm-12 178V68h24v150h-24zm215 0h-30l-26-95c-2-5-3-12-3-18h-1l-3 18-26 95h-30L815 68h35l22 97 3 21 4-21 26-97h28l26 97 3 21h1l3-21 23-97h30l-42 150zm92-63c3 27 17 42 42 42 14 0 28-4 37-9l8 22c-12 6-31 11-50 11-45 0-70-31-70-78s24-78 65-78c38 0 60 26 60 72v5l-92 13zm31-69c-22 0-33 20-33 49v3l62-9c0-28-9-43-29-43zm141 135c-20 0-38-4-49-11V0h33v72c8-4 18-6 29-6 36 0 60 28 60 73 0 50-26 82-73 82zm4-135c-7 0-14 1-20 4v107c5 2 11 3 18 3 26 0 37-23 37-58s-12-56-35-56z" />
			</svg>

			<div>
				<h1>Log in to your site</h1>

				<Link href="/create">
					<button>
						<Icon path={mdiArrowRightThin} className='icon icon-left' />
						Create your new site in minutes
					</button>
				</Link>


				<p>Log in to Jiko using the email "lem@wasabiweb.se" and the password "test"</p>

				{errorMessage !== false &&
					<h3>{errorMessage}</h3>
				}

				<form onSubmit={handleSubmit} id="loginForm">
					<label>Email</label>
					<input name="email" type="email" size="30" required aria-required="true" />

					<label>Password</label>
					<input name="pass" type="password" required aria-required="true" />

					<br/>
					<button type="submit" form="loginForm" value="Login">
						<Icon path={mdiLogin} className='icon icon-left' />
						Log in
					</button>
				</form>
			</div>
		</div>
	</div>
}

export default Login
