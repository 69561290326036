
import { useQuery } from '@tanstack/react-query'

const Request = {
	// Will contain JWT for all render sites and API domain under __main
	_tokens: {},
	// Active token refresh timeout id, so it can be canceled if needed
	_refreshTimeout: -1,

	// Triggered by timeout to refersh tokens before they expire
	refreshTokens: () => {
		// Can't refresh what has not been set in the first place
		if (!Request._tokens['__main']) return

		clearTimeout(Request._refreshTimeout)

		fetch(`${process.env.REACT_APP_API_DOMAIN}/v1/refresh_tokens`, {
			method: 'POST',
			headers: {
				'X-token': Request._tokens['__main']
			}
		}).then(res => {
			return res.json()
		}).then(json => {
			Request._tokens = json.tokens

			// Overwrite main API token stored on disk so it survives a page refresh
			let storage = JSON.parse(localStorage['UserContext'])
			storage.token = Request._tokens['__main']

			// Do the same for all other sites
			for (let siteID in storage.sites) {
				storage.sites[siteID].token = Request._tokens[siteID]
			}

			localStorage['UserContext'] = JSON.stringify(storage)

			// Run this every half an hour to keep the tokens fresh
			Request._refreshTimeout = setTimeout(Request.refreshTokens, 1800000)
		})
		.catch(err => {
			console.warn('Cannot refresh tokens, retry in 10s', err)
			Request._refreshTimeout = setTimeout(Request.refreshTokens, 10000)
		})
	},

	updateTokens: user => {
		Request._tokens['__main'] = user.token

		for (let siteID in user.sites) {
			Request._tokens[siteID] = user.sites[siteID].token
		}

		clearTimeout(Request._refreshTimeout)
		Request._refreshTimeout = setTimeout(Request.refreshTokens, 1800000)

		console.log(Request._tokens);
	},

	main: {
		State: (id, path) => {

		},

		Action: (path, options = {}) => {
			let headers = {'Content-Type': 'application/json'}
			let payload = options.payload ? JSON.stringify(options.payload) : '{}'

			// Do not add token header if requested not to
			if (!options.skipAuth) headers['X-token'] = Request._tokens['__main']

			return fetch(`${process.env.REACT_APP_API_DOMAIN}/v1${path}`, {
				method: 'POST',
				headers: headers,
				body: payload
			}).then(res => {
				return res.json()
			})
		}
	},

	render: {
		State: (id, path, activeSite) => {
			return useQuery({
				queryKey: [id],
				queryFn: () => {
					return fetch(process.env.REACT_APP_RENDER_DOMAIN.replace('SITEID', activeSite) + '/ww-pe-api' + path, {
						headers: {
							'X-token': Request._tokens[activeSite]
						}
					}).then(res => {
						return res.json()
					})
				}
			})
		},

		Action: (path, activeSite, options = {}) => {
			let payload = options.payload ? JSON.stringify(options.payload) : '{}'

			return fetch(`${process.env.REACT_APP_RENDER_DOMAIN.replace('SITEID', activeSite)}/wp-json/ww-jiko/v1${path}`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'X-token': Request._tokens[activeSite]
				},
				body: payload
			}).then(res => {
				return res.json()
			})
		}
	}
}

Request._refreshTimeout = setTimeout(Request.refreshTokens, 1800000)

export default Request;
