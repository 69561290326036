import './ImageModal.scss';

import {useImperativeHandle, forwardRef, useState, useContext} from 'react'
import Modal from 'react-modal'
import {useQuery} from '@tanstack/react-query'

import {UserContext} from '../../App.js'
import Mutations from '../Mutations'
import TabImageList from './TabImageList'
import TabGenerate from './TabGenerate'

const ImageModal = forwardRef((props, ref) => {
	Modal.setAppElement('#root')

	const {user, setUser} = useContext(UserContext)
	const [isOpen, setIsOpen] = useState(false)
	const [activeTab, setActiveTab] = useState('list')
	// Contains metadata about the field this image will go in, so that it can be updated with the new image
	const [fieldMeta, setFieldMeta] = useState({})

	// Select image that is already on the site
	function selectNewImage(image) {
		if (fieldMeta.callback) fieldMeta.callback(image)
		close()
	}

	// Start the upload of a new image
	function uploadImage(file) {
		Mutations.uploadImage(fieldMeta.sectionKey, fieldMeta.fieldName, file, fieldMeta.setter, fieldMeta.callback, props.domain, user)
		close()
	}

	// Cleanly close the modal
	function close() {
		setIsOpen(false)
		setActiveTab('list')
	}

	// Can be triggered through the ref to open the modal
	useImperativeHandle(ref, () => ({
		select(metaObj) {
			setIsOpen(true)
			setFieldMeta(metaObj)
		}
	}))


	return <Modal
		isOpen={isOpen}
		onRequestClose={close}>

		{activeTab == 'list' && <TabImageList
			domain={props.domain}
			setIsOpen={setIsOpen}
			uploadImage={uploadImage}
			selectNewImage={selectNewImage}
			setActiveTab={setActiveTab} />}

		{activeTab == 'generate' && <TabGenerate
			domain={props.domain}
			setIsOpen={setIsOpen}
			uploadImage={uploadImage} />}
	</Modal>
})

export default ImageModal
