import { useState, createContext } from 'react'
import { Switch, Route } from 'wouter'

import Request from './Request'

import SiteSettings from './sitesettings/SiteSettings'
import PageList from './pagelist/PageList'
import Editor from './editor/Editor'
import Login from './login/Login'
import Create from './create/Create'

export const UserContext = createContext()

const App = () => {
	const [user, setUser] = useState({
		token: false,
		email: '',
		activeSite: '',
		sites: []
	})

	// Restore user settings from local storage if needed
	if (localStorage['UserContext'] && user.token === false) {
		user.token = ''

		try {
			let storage = JSON.parse(localStorage['UserContext'])

			for (let key of ['token', 'email', 'sites', 'activeSite']) {
				if (storage[key]) user[key] = storage[key]
			}

			Request.updateTokens(user)
			setUser(user)
		} catch (e) {}
	}

	return <div className="App">
		<UserContext.Provider value={{user: user, setUser: setUser}}>

			<Switch>
				<Route path="/create" component={Create} />
				<Route path="/site/:siteid/page/:pageid" component={Editor} />
				<Route path="/site/:siteid/settings/:tab" component={SiteSettings} />
				<Route path="/site/:siteid" component={PageList} />
				<Route component={Login} />
			</Switch>

		</UserContext.Provider>
	</div>
}

export default App
