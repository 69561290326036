import './Create.scss'

import { useState, useContext } from 'react'
import { useLocation } from "wouter";
import Icon from '@mdi/react'
import { mdiEmail, mdiCursorText, mdiFormatColorFill, mdiImage, mdiLoading } from '@mdi/js'

import { UserContext } from '../App'
import Request from '../Request'

const Create = props => {
	const {user, setUser} = useContext(UserContext)
	const [location, setLocation] = useLocation();

	const [stage, setStage] = useState(1)
	const [busy, setBusy] = useState(false)
	const [logos, setLogos] = useState([])

	const [email, setEmail] = useState('')
	const [title, setTitle] = useState('')
	const [color, setColor] = useState('')
	const [customColor, setCustomColor] = useState('#98C923')
	const [logo, setLogo] = useState('')

	console.log(user);

	function createUser() {
		setBusy(true)

		Request.main.Action('/create/new_user', {
			skipAuth: true,
			payload: {
				email: email
			}
		}).then(resp => {
			if (resp.success) {
				user.email = email
				user.token = resp.token
				setUser(user)

				Request.updateTokens({
					token: resp.token
				})

				setBusy(false)
				setStage(2)
			}
		})
	}

	function createSite() {
		setBusy(true)

		Request.main.Action('/create/new_site', {
			payload: {
				title: title
			}
		}).then(resp => {
			if (resp.success) {
				user.activeSite = resp.pubid
				setUser(user)

				Request.refreshTokens()

				setBusy(false)
				setStage(3)
			}
		})
	}

	function showLogos() {
		setBusy(true)

		Request.main.Action('/create/generate_logi',{
			payload: {
				title: title
			}
		}).then(resp => {
			if (resp.success) {

				setBusy(false)
				setLogos(resp.svgs)
				setStage(4)
			}
		})
	}

	function saveSettings() {
		setBusy(true)

		Request.render.Action('/settings', user.activeSite, {
			payload: {
				blogname: title,
				colors: {
					color_brand: color
				},
				logos: {
					light: logo.replaceAll('#333333', '#fff'),
					dark: logo.replaceAll('#333333', color)
				}
			}
		}).then(resp => {
			if (resp.success) {
				setLocation(`/site/${user.activeSite}/page/5`)
			}
			else {
				setTimeout(() => {
					saveSettings()
				}, 3000);
			}
		})
		.catch(() => {
			setTimeout(() => {
				saveSettings()
			}, 3000);
		})
	}

	function updateAccentColor(newColor) {
		setColor(newColor)
		document.querySelector(':root').style.setProperty('--color-accent', newColor);
	}

	return <div className="Create">
		<div className="CreateProgress">
			<span>You're only <strong>seconds away</strong> from having your website up and running!</span>

			<div className={stage > 0 ? 'active' : ''}>
				<div><Icon path={mdiEmail} className='icon' /></div>
				<div>Email</div>
			</div>
			<div className={stage > 1 ? 'active' : ''}>
				<div><Icon path={mdiCursorText} className='icon' /></div>
				<div>Title</div>
			</div>
			<div className={stage > 2 ? 'active' : ''} onClick={() => {if (stage > 3) setStage(3)}}>
				<div><Icon path={mdiFormatColorFill} className='icon' /></div>
				<div>Color</div>
			</div>
			<div className={stage > 3 ? 'active' : ''}>
				<div><Icon path={mdiImage} className='icon' /></div>
				<div>Logo</div>
			</div>
		</div>

		<div className="CreateStages">
			{stage == 1 && <div>
				<h6>Email address</h6>
				<p>An email address you already have and where we can reach you for a confirmation email. No spam!</p>

				<input type="email" placeholder="name@domain.com" value={email} onChange={e => setEmail(e.target.value)} disabled={busy} />
				<button onClick={createUser}>
					{busy ? <Icon path={mdiLoading} className='icon icon-spinner' /> : 'Next'}
				</button>
			</div>}

			{stage == 2 && <div>
				<h6>Site title</h6>
				<p>A nice name for your site. Usually the first thing people see in search results.</p>

				<input type="text" placeholder="My amazing flower store" value={title} onChange={e => setTitle(e.target.value)} disabled={busy} />
				<button onClick={createSite}>
					{busy ? <Icon path={mdiLoading} className='icon icon-spinner' /> : 'Next'}
				</button>
			</div>}

			{stage == 3 && <div>
				<h6>Accent color</h6>
				<p>Pick a color that best fits your site, or choose a completely custom color.</p>

				<div className="CreateColors">
					<div onClick={() => {updateAccentColor('#9B1D20')}} className={color == '#9B1D20' ? 'active' : ''}>
						<div style={{background: '#9B1D20'}}></div>
						Carmine
					</div>
					<div onClick={() => {updateAccentColor('#3D2B3D')}} className={color == '#3D2B3D' ? 'active' : ''}>
						<div style={{background: '#3D2B3D'}}></div>
						Dark purple
					</div>
					<div onClick={() => {updateAccentColor('#21D19F')}} className={color == '#21D19F' ? 'active' : ''}>
						<div style={{background: '#21D19F'}}></div>
						Mint
					</div>
					<div onClick={() => {updateAccentColor('#416788')}} className={color == '#416788' ? 'active' : ''}>
						<div style={{background: '#416788'}}></div>
						Lapis Lazuli
					</div>
					<div onClick={() => {updateAccentColor('#D282A6')}} className={color == '#D282A6' ? 'active' : ''}>
						<div style={{background: '#D282A6'}}></div>
						Thulian
					</div>
					<div onClick={() => {updateAccentColor('#303A2B')}} className={color == '#303A2B' ? 'active' : ''}>
						<div style={{background: '#303A2B'}}></div>
						Dark olive
					</div>
					<div onClick={() => {updateAccentColor('#CFCFEA')}} className={color == '#CFCFEA' ? 'active' : ''}>
						<div style={{background: '#CFCFEA'}}></div>
						Lavender
					</div>
					<div onClick={() => {updateAccentColor('#FCB5B5')}} className={color == '#FCB5B5' ? 'active' : ''}>
						<div style={{background: '#FCB5B5'}}></div>
						Melon
					</div>
					<div onClick={() => {updateAccentColor('#DA3E52')}} className={color == '#DA3E52' ? 'active' : ''}>
						<div style={{background: '#DA3E52'}}></div>
						Amaranth
					</div>
					<div onClick={() => {updateAccentColor('#F2E94E')}} className={color == '#F2E94E' ? 'active' : ''}>
						<div style={{background: '#F2E94E'}}></div>
						Maize
					</div>
					<div onClick={() => {updateAccentColor('#7E6B8F')}} className={color == '#7E6B8F' ? 'active' : ''}>
						<div style={{background: '#7E6B8F'}}></div>
						Violet
					</div>
					<div onClick={() => {updateAccentColor('#2A2B2A')}} className={color == '#2A2B2A' ? 'active' : ''}>
						<div style={{background: '#2A2B2A'}}></div>
						Jet
					</div>
					<div onClick={() => {updateAccentColor('#4C9F70')}} className={color == '#4C9F70' ? 'active' : ''}>
						<div style={{background: '#4C9F70'}}></div>
						Shamrock
					</div>
					<div onClick={() => {updateAccentColor('#011638')}} className={color == '#011638' ? 'active' : ''}>
						<div style={{background: '#011638'}}></div>
						Oxford
					</div>
					<div onClick={() => {updateAccentColor('#70E4EF')}} className={color == '#70E4EF' ? 'active' : ''}>
						<div style={{background: '#70E4EF'}}></div>
						Electric blue
					</div>
					<label className={color == customColor ? 'active' : ''}>
						<div style={{background: customColor}}></div>
						Custom color
						<input type="color" onChange={event => {setCustomColor(event.target.value);updateAccentColor(event.target.value)}} />
					</label>

				</div>

				<button onClick={showLogos} disabled={color == ''}>
				{busy ? <Icon path={mdiLoading} className='icon icon-spinner' /> : 'Next'}
				</button>
			</div>}

			{stage == 4 && <div>
				<h6>Logo</h6>
				<p>Almost done! Pick a temporary logo that best fits your site. Don't worry, you can change this at any time in the site settings.</p>

				<div className="CreateLogos">
					{logos.map((svgLogo, index) => {
						return <div onClick={() => {setLogo(svgLogo)}} className={logo == svgLogo ? 'active' : ''} key={index}>
							<div style={{backgroundImage: 'url("data:image/svg+xml;base64,' + btoa(svgLogo.replaceAll('#333333', color)) + '")'}}></div>
						</div>
					})}
				</div>

				<button onClick={saveSettings}>
					{busy ? <Icon path={mdiLoading} className='icon icon-spinner' /> : 'Next'}
				</button>
			</div>}
		</div>
	</div>
}

export default Create
