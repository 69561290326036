import {useContext} from 'react'
import { useLocation } from "wouter";

import { UserContext } from '../App.js'

const CreateButton = () => {
	const {user} = useContext(UserContext)
	const [location, setLocation] = useLocation();

	function createNewPage() {
		fetch(process.env.REACT_APP_RENDER_DOMAIN.replace('SITEID', user.activeSite) + '/ww-pe-api/createpage/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'X-token': user.sites[user.activeSite].token
			},
			body: JSON.stringify({
				title: prompt('Gib page title pls', 'New page')
			})
		}).then(res => {
			return res.json()
		}).then(json => {
			setLocation('/site/' + user.sites[user.activeSite].pub_id + '/page/' + json.post_id)
		})
	}

	return <button onClick={createNewPage}>Add new page</button>
};

export default CreateButton;
