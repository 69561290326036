import './PageList.scss'

import { useContext } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Link } from 'wouter'

import { UserContext } from '../App'
import Request from '../Request'

import TopBar from '../TopBar'
import CreateButton from './CreateButton'
import Page from './Page'
import PagePlaceholder from './PagePlaceholder'


const PageList = () => {
	const { user } = useContext(UserContext)

	const { data } = Request.render.State('pages', '/pages', user.activeSite)

	let sites = []

	if (user.sites) {
		for (let id in user.sites) {
			sites.push(<div key={id}>{user.sites[id].name}</div>)
		}
	}

	let pages = Array(8).fill().map((_, index) => <PagePlaceholder key={index} />)

	if (data && data.pages) {
		pages = data.pages.map((page, index) => {
			return <Page key={page.id}
				id={page.id}
				title={page.title}
				modified={page.modified} />
		})
	}

	return <div className="PageList">
		<TopBar />

		<div>
			<div>
				<h2>
					My sample website
				</h2>
				<div>
					<CreateButton />
					<Link href={'/site/' + user.activeSite + '/settings/menu'}>
						<button>Site settings</button>
					</Link>
				</div>
			</div>

			<div>
				{pages}
			</div>
		</div>
	</div>
}

export default PageList
