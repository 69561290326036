import { useState, useRef, useEffect, useImperativeHandle, forwardRef, useContext } from 'react'

import { UserContext } from '../../App.js'

import SectionHandle from './SectionHandle'
import SectionPlaceholder from './SectionPlaceholder'
import AddBannerButton from './AddBannerButton'
import AddSectionButton from './AddSectionButton'

const SCALE_SIZE = 5

const SectionRoot = forwardRef((props, ref) => {
	const {user} = useContext(UserContext)

	// Wether an operation is being preformed and the user can't interact with the sections
	const [isBusy, setIsBusy] = useState(false)
	// The section currently being dragged
	const [dragSection, setDragSection] = useState(false)
	// Haw far the mouse is off the top of the container
	const [dragOffset, setDragOffset] = useState(0)
	// The location of the gray box that is a drop target, -1 is before any element and a positive number is after that index
	const [placeholderIndex, setPlaceholderIndex] = useState(0)
	// Contains the distance between the mouse click and the top of the section element
	const [cursorOffset, setCursorOffset] = useState(0)
	// Height of the active section, so the placeholder fits neatly in the same space
	const [placeholderHeight, setplaceholderHeight] = useState(0)

	// Keeps a reference to current scroll position and
	const scrollPointerRef = useRef(-1)
	// Reference to the section container
	const rootRef = useRef(null)

	// Called by SectionRoot to initiate a move of the active section
	const changeActiveSection = sectionId => {
		for (let index in props.sections) {
			if (sectionId === props.sections[index].key) {
				return props.setActiveSection(parseInt(index))
			}
		}
	}

	// Called by SectionRoot to initiate a move of the active section
	const reorderSection = (moveToIndex, newActive = -2) => {
		fetch(props.domain + '/ww-pe-api/reorder/' + props.postID + '/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'X-token': user.sites[user.activeSite].token
			},
			body: JSON.stringify({
				id: dragSection,
				after: moveToIndex === -1 ? 'start' : props.sections[moveToIndex].key
			})
		}).then(res => {
			return res.json()
		}).then(json => {
			// Set the active index to the move index, as that's where it has moved to
			props.setActiveSection(newActive > -1 ? newActive : moveToIndex)
			// Refresh the page with the new order
			props.platformManagerRef.current.refresh()
		})
	}

	// Called by the SectionHandle to delete itself
	function deleteSection(idhash) {
		setIsBusy(true)

		let newActive = 0
		let isBanner = false

		// Find the to be deleted section in the array
		for (let index in props.sections) {
			if (idhash === props.sections[index].key) {
				// If the module key is 2XXX, it's a banner and the new active section should be the first section
				if (props.sections[index].module.substring(1, 0) === '2') isBanner = true
				// Otherwise it's a normal section, so the new active section is the one before it
				else newActive = Math.max(index - 1, 0)

				break
			}
		}

		// Execute delete
		fetch(props.domain + '/ww-pe-api/removesection/' + props.postID + '/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'X-token': user.sites[user.activeSite].token
			},
			body: JSON.stringify({
				id: idhash,
				is_banner: isBanner
			})
		}).then(res => {
			return res.json()
		}).then(json => {
			props.setActiveSection(newActive)
			props.platformManagerRef.current.refresh()
		})
	}


	// Executed within SectionHandle when drag starts
	function startDrag(sectionId, offset) {
		// Get the cursor location within the section element so it keeps the same place relative to the cursor when dragging
		// Get the in-scale distance to the top of the section list
		let sectionTop = rootRef.current.querySelector('#' + sectionId).getBoundingClientRect().top
		// Get the difference between the top of the section and the cursor
		// The resulting value is the amount of pixels the cursor is down from the top of the element
		let curOffset = (offset - sectionTop) / SCALE_SIZE
		setCursorOffset(curOffset)

		scrollPointerRef.current = (offset + curOffset * 2) / SCALE_SIZE
		setDragOffset(scrollPointerRef.current)
		calcPlaceholderLocation(scrollPointerRef.current)

		props.setIsDragging(true)
		setDragSection(sectionId)

		// Match placeholder height to hight of dragged element
		// Can't use getElementById() because of iframe
		setplaceholderHeight(rootRef.current.querySelector('#' + sectionId).clientHeight)

		// Start scroll check
		window.requestAnimationFrame(checkScrollPosition.bind(sectionId))

		// Called every frame (outside of react) to smoothly animate the scroll position if a section is dragged to the very top or bottom
		function checkScrollPosition() {
			// The size of the area in which a dragged section starts scrolling
			const SCROLL_AREA_SIZE = document.body.scrollHeight / 20
			// The max scroll speed
			const SCROLL_SPEED = 0.1
			// The factor of how much to multiply the speed the closer to the edge it is
			const SCROLL_ACCELERATION = 1.4

			let heightOfSection = rootRef.current.querySelector('#' + sectionId).clientHeight / SCALE_SIZE
			let accldDistance = 0

			// Auto scroll up if a top edge
			if (scrollPointerRef.current < heightOfSection - cursorOffset + SCROLL_AREA_SIZE) {
				accldDistance = Math.pow(heightOfSection - cursorOffset + SCROLL_AREA_SIZE - scrollPointerRef.current, SCROLL_ACCELERATION) * SCROLL_SPEED * -1
			}
			// Auto scroll down if at bottom edge
			else if (scrollPointerRef.current > document.body.scrollHeight - cursorOffset - SCROLL_AREA_SIZE) {
				accldDistance = Math.pow(SCROLL_AREA_SIZE + scrollPointerRef.current - document.body.scrollHeight - cursorOffset, SCROLL_ACCELERATION) * SCROLL_SPEED
			}

			// Apply scrolling
			rootRef.current.scrollTop = rootRef.current.scrollTop + accldDistance

			// Keep checking scroll prosition as long as the drag is active
			if (scrollPointerRef.current >= 0) window.requestAnimationFrame(checkScrollPosition.bind(sectionId))
		}
	}


	// Apply scrolling to the scaled element
	function onScroll(event) {
		rootRef.current.scrollTop = rootRef.current.scrollTop + event.deltaY
	}


	// When dragging, calculate where the section would be dropped if it was released
	function calcPlaceholderLocation(dragOffset) {
		let index = 0
		let offset = dragOffset - 25 * SCALE_SIZE

		// Go through each section top to bottom, and see where it should fit after
		for (let childIndex in rootRef.current.children) {
			if (!rootRef.current.children.hasOwnProperty(childIndex)) continue
			let child = rootRef.current.children[childIndex]

			// Ifnore non-section elements and skip the section that is currently dragging
			if (child.className.includes('AddBannerButton') || child.className.includes('SectionPlaceholder')) continue
			if (child.className.includes('dragging')) {
				index++
				continue
			}

			let rect = child.getBoundingClientRect()

			// If we're the first section and it should have been places before us, do so
			if (index === 0 && rootRef.current.scrollTop < 20 && (rect.height / 2) / SCALE_SIZE > offset) {
				return setPlaceholderIndex(-1)
			}

			// If it should be placed after this section, do so
			if ((rect.top) / SCALE_SIZE > offset) {
				return setPlaceholderIndex(index - 1)
			}

			// Otherwise we're too far up still, check the next section down
			index++
		}
	}

	function mouseMove(event) {
		// Do not waste calculation time if not mounted or not dragging
		if (dragSection === false || !rootRef.current || !rootRef.current.children) return

		// Pointer pixel count from top edge of frame
		let offset = event.pageY / SCALE_SIZE

		// Cap the offset so it does not move the section out of the top
		let heightOfSection = rootRef.current.querySelector('#' + dragSection).clientHeight / SCALE_SIZE
		if (offset + cursorOffset < heightOfSection) offset = heightOfSection - cursorOffset

		// Redender with the new mouse position
		scrollPointerRef.current = offset + cursorOffset
		setDragOffset(scrollPointerRef.current)
		calcPlaceholderLocation(scrollPointerRef.current)
	}

	// Functions that can be called from our ref
	useImperativeHandle(ref, () => ({
		mouseRelease(event) {
			props.setIsDragging(false)
			setDragSection(false)
			setCursorOffset(0)

			scrollPointerRef.current = -1

			setIsBusy(true)
			reorderSection(placeholderIndex)
		}
	}))

	// Reset busy flag if the sections have been updated in platform
	useEffect(() => {
		setIsBusy(false)
	}, [props.sections])


	let sections = []
	let hasBanner = props.sections[0] && props.sections[0].module.substring(1, 0) === '2'

	// Show a button to add a banner if there isn't one already
	if (!props.sections[0] || !hasBanner) {
		sections.push(<AddBannerButton
			key='bannerbutton'
			setIsBusy={setIsBusy}
			domain={props.domain}
			postID={props.postID}
			platformManagerRef={props.platformManagerRef} />)
	}

	// If we're dragging a section and the dropzone is before the first element in the list
	if (placeholderIndex === -1 && dragSection !== false) {
		// If there's a banner it should not be possible to drop it before the banner, so move it to after the banner
		if (hasBanner) setPlaceholderIndex(0)
		// If there's no baner, allow dropping just under the add banner button
		else sections.push(<SectionPlaceholder key='placeholder' placeholderHeight={placeholderHeight} />)
	}

	// Go through each section on the page and add a handle for them
	for (let index in props.sections) {
		if (!props.sections.hasOwnProperty(index)) continue
		let section = props.sections[index]

		section.className = ''
		if (props.activeSection === parseInt(index)) {
			section.className += 'active'
		}
		if (dragSection === section.key) {
			section.className += ' dragging'
		}
		if (section.module.substring(1, 0) === '2') {
			section.className += ' banner'
		}

		sections.push(<SectionHandle
			key={index}
			section={section}
			setIsBusy={setIsBusy}
			startDrag={startDrag}
			dragOffset={dragSection === section.key ? dragOffset : -1}
			deleteSection={deleteSection}
			reorderSection={reorderSection}
			activeSection={props.activeSection}
			changeActiveSection={changeActiveSection} />)

		// If a section is being dragged and the "ghost" of ti should be placed after this section, do so
		if (placeholderIndex === parseInt(index) && dragSection !== false) {
			sections.push(<SectionPlaceholder key="placeholder" placeholderHeight={placeholderHeight} />)
		}
	}


	return <div
		className={'SelectionRoot' + (isBusy ? ' busy' : '')}
		ref={rootRef}
		onMouseMove={mouseMove}
		onWheel={onScroll}>
			{sections}
		<AddSectionButton modal={props.modal} />
	</div>
});

export default SectionRoot;
