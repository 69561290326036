import './TabBasic.scss'

import { useState, useContext } from 'react'
import { useQuery } from '@tanstack/react-query'

import Icon from '@mdi/react';
import { mdiDrag } from '@mdi/js';
import { mdiDelete } from '@mdi/js';

const TabBasic = () => {

	const [items, setItems] = useState([])
	const [unsavedChanges, setUnsavedChanges] = useState(false)

	return <div className="TabBasic">
		<h2>
			Basics

		</h2>

		<strong>Site title</strong>
		<input type="text" />


	</div>
}

export default TabBasic
