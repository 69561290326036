import { useEffect, useImperativeHandle, forwardRef, useRef } from 'react'

const PlatformManager = forwardRef((props, ref) => {
	const iframeRef = useRef(null)

	useEffect(() => {
		const handleIncomingMessage = (event) => {
			if (event.origin !== props.src.split('?')[0]) return;

			// console.info('Message from iframe:', event.data)

			switch (event.data.msgType) {
				case 'pageContentUpdate':
					if (event.data.styles) {
						props.setPlatformStyles(<style>
							{event.data.styles.replaceAll('</style>', '')}
							{'.c-loading__spinner {display: none}'}
						</style>)
					}

					props.setSections(event.data.sections)

					// Push the activeSection so it can scroll into view
					if (props.sections[props.activeSection]) {
						iframeRef.current.contentWindow.postMessage({
							msgType: 'activeSectionUpdate',
							section: props.sections[props.activeSection].key,
							behavior: 'instant'
						}, props.src)
					}

					break

				case 'dynamicInput':
					// Do not execute before any sections are known
					if (!props.sections.length) return

					let updateSections = [...props.sections]

					for (let field of updateSections[props.activeSection].fields) {
						if (field.key === event.data.key) field.value = event.data.content
					}

					props.setSections(updateSections)
					break

				default:
					console.warn('Inknown message type from platform iframe: ' + event.data.msgType);
					return
			}
		}

		window.addEventListener('message', handleIncomingMessage)

		return () => {
			window.removeEventListener('message', handleIncomingMessage)
		}
	}, [props])

	// Update the iframe on an active section change, scrolls it into view
	useEffect(() => {
		if (props.sections[props.activeSection]) {
			iframeRef.current.contentWindow.postMessage({
				msgType: 'activeSectionUpdate',
				section: props.sections[props.activeSection].key,
				behavior: 'smooth'
			}, props.src)
		}
	}, [props.activeSection, props.src, props.sections, iframeRef])

	// Functions that can be called from our ref
	useImperativeHandle(ref, () => ({
		sendUpdateMessage(section, key, content) {
			iframeRef.current.contentWindow.postMessage(
				{
					msgType: 'contentUpdate',
					section: section,
					key: key,
					content: content
				},
				props.src
			)
		},

		refresh() {
			iframeRef.current.src += ''
		}
	}))



	return <iframe src={props.src} title="platform container" ref={iframeRef}></iframe>
})

export default PlatformManager
