import './SectionModal.scss';

import { useImperativeHandle, forwardRef, useState, useContext } from 'react'
import Modal from 'react-modal'
import { useQuery } from '@tanstack/react-query'

import { UserContext } from '../App.js'


const SectionModal = forwardRef((props, ref) => {
	Modal.setAppElement('#root')

	const {user} = useContext(UserContext)

	const [isOpen, setIsOpen] = useState(false)
	const [searchTerm, setSearchTerm] = useState('')

	useImperativeHandle(ref, () => ({
		open() {
			setIsOpen(true)
			setSearchTerm('')
		}
	}))

	function handleKeyPress(event) {
		// Enter, apply first matching module (if any)
		if (event.keyCode === 13) {
			for (let key in data.modules) {
				if (key.substring(0, 1) === '2') continue
				let mod = data.modules[key]

				if ((mod.name + mod.desc + mod.tags).toLowerCase().includes(searchTerm)) {
					return addSection(key)
				}
			}
		}
		// Esc, close modal
		else if (event.keyCode === 27) {
			setIsOpen(false)
		}
	}

	function addSection(key) {
		fetch(props.domain + '/ww-pe-api/addsection/' + props.postID + '/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'X-token': user.sites[user.activeSite].token
			},
			body: JSON.stringify({
				key: key
			})
		}).then(res => {
			return res.json()
		}).then(json => {
			setIsOpen(false)
			props.platformManagerRef.current.refresh()
			props.setActiveSection(props.activeSection + 1)
		})
	}

	const { data } = useQuery({
		queryKey: ['modules'],
		queryFn: async () => {
				let response = await fetch(props.domain + '/ww-pe-api/listmodules/', {
				headers: {
					'X-token': user.sites[user.activeSite].token
				}
			})

			let data = await response.json()
			return data
		}
	})

	let matchingSections = []
	let hiddenSections = []

	if (data && data.modules) {

		for (let key in data.modules) {
			// Don't show banner sections
			if (key.substring(0, 1) === '2') continue

			let mod = data.modules[key]
			let searchMatch = (mod.name + mod.desc + mod.tags).toLowerCase().includes(searchTerm)
			let targetArray = (searchMatch ? matchingSections : hiddenSections)

			let svgPaths = mod.svg.map(path => {
				return <path className={path[0]} d={path[1]} />
			})

			targetArray.push(<div key={key} className={searchMatch ? '' : 'hidden'} onClick={() => {addSection(key)}}>
				<strong>{mod.name}</strong>

				<svg viewBox="0 0 400 200">
					{svgPaths}
				</svg>

				<p>{mod.desc}</p>
			</div>)
		}
	}
	else {
		matchingSections = [<span>Still loading!</span>]
	}


	return <Modal
		isOpen={isOpen}
		onRequestClose={() => {setIsOpen(false)}}>
		<div className="SectionModal">

			<div>
				<input
				value={searchTerm}
				type="text"
				autoFocus
				placeholder="Start typing module name..."
				onChange={(e) => {setSearchTerm(e.target.value.toLowerCase())}}
				onKeyDown={handleKeyPress} />
				<button onClick={() => {setIsOpen(false)}}>X</button>
			</div>

			<div>
				{matchingSections}
				{hiddenSections}
			</div>
		</div>
	</Modal>
})

export default SectionModal
