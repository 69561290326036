import './SaveButton.scss';

import {useImperativeHandle, forwardRef, useContext} from 'react'
import { UserContext } from '../App.js'

import Mutations from './Mutations'

const SaveButton = forwardRef((props, ref) => {
	const {user} = useContext(UserContext)

	function triggerSave() {
		fetch(props.domain + `/ww-pe-api/save/${props.postID}/`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'X-token': user.sites[user.activeSite].token
			},
			body: JSON.stringify({
				post: props.postID,
				mutations: Mutations.all()
			})
		}).then(res => {
			return res.json()
		}).then(json => {
			if (json.success) {
				props.platformManagerRef.current.refresh()

				if (props.publish) {
					fetch(`${process.env.REACT_APP_API_DOMAIN}/v1/site/${user.activeSite}/page/${props.postID}/publish`, {
						method: 'POST',
						headers: {
							'X-token': user.token
						}
					}).then(res => {
						return res.json()
					}).then(json => {
						console.log(json);
					})
				}
			}
		})
	}

	useImperativeHandle(ref, () => ({
		forceSave() {
			triggerSave()
		}
	}))

	return <button onClick={triggerSave} className="SaveButton">
		{props.publish ? 'Publish' : 'Save'}
	</button>
})

export default SaveButton
